import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtener() {
    var url = ConfigAPI.baseURL + "reports-services" + Session.getToken();
    return instance.get(url);
  },
  mostrar(id) {
    var url = ConfigAPI.baseURL + "reports-services/" + id + Session.getToken();
    return instance.get(url);
  },
  agregar(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "reports-services" + Session.getToken();
    return instance.post(url,params);
  },
  editar(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "reports-services/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminar(id) {
    var url = ConfigAPI.baseURL + "reports-services/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  filtrarReport(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "reports-services/filter/query" + Session.getToken();
    return instance.post(url,params);
  },  

  obtenerClientes() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },
  obtenerClientesByZonas(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sellers/filter/customerByZone" + Session.getToken();
    return instance.post(url,params);     
  },
  obtenerStaff() {
    var url = ConfigAPI.baseURL + "staff" + Session.getToken();
    return instance.get(url);
  },  
  obtenerVendedores() {
    var url = ConfigAPI.baseURL + "sellers" + Session.getToken();
    return instance.get(url);
  }, 
  mostrarVendedorWithCustomers(id) {
    var url = ConfigAPI.baseURL + "sellers/"+id+"/withCustomers" + Session.getToken();
    return instance.get(url);
  },    
}

export default servicesAPI;
